import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Quote from 'components/Quote';
import timeline from 'components/timelineComponent';
import InteractiveTimeline from 'components/InteractiveTimeline';
import Image from 'components/Image';
import SplitView, { viewTypes } from 'templates/SplitView';
import Modal from 'components/ChapterModal';
import { stickyPositions, modalBackgrounds } from 'components/ModalContent';
import { AppSettings } from 'components/Layout';
import ScrollContainer from 'components/ScrollContainer';
import { tabbable } from 'utils/accessibility';

import 'styles/components/standard-interactive-timeline.scss';
import './timeline-1965-present.scss';

const simpleModal = (headline, content) => {
  return (
    <div className="standard-interactive-timeline__simple-modal">
      <p>{headline}</p>
      <p className="alt" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

const defaultDateData = [0, 1, 1, 0, 0, 0];
const getDataContents = (images) => {
  return {
    date_1: {
      date: {
        start: new Date(1965, ...defaultDateData)
      },
      headline: '1965',
      subHeadline:
        'August 6, 1965, President Johnson signs the Voting Rights Act (VRA) of 1965. ',
      content:
        'The Act bans literacy tests and empowers the U.S. Department of Justice and federal courts to monitor jurisdictions with a history of discrimination. <br /><br />Most importantly, the VRA requires those jurisdictions with a history of discrimination to receive federal approval before they make any changes in voting procedures or requirements.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image1965} alt="President Johnson Signs the Voting Rights BillPresident Johnson signs the 1965 Voting Rights bill in law in the President's Room at the U.S. Capitol." showAria />
    },
    date_2: {
      date: {
        start: new Date(1970, ...defaultDateData)
      },
      headline: '1970',
      subHeadline:
        'The first renewal of the VRA includes a clause extending the right to vote to 18 year olds.',
      content:
        'This is confirmed by the ratification of the 26th Amendment in 1971.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image1970} showAria alt="July 5, 1971-Washington,D.C. Pres.Nixon affixes his signature to signify that he witness to the certification of ratification of the 26th amendment of the Constitution of the U.S. 7/5 which gives 18-year-olds the right to vote. At left is Robert Kunzig, chief of the Gen. Services Admin., who performed the actual certification. At right is Paul Larimer, 18, of Concord,Calif., who was also an official witness. Larimer is a member of 'Young Americans in Concert' which performed at the ceremony. UPI b/w photograph."/>
    },
    date_3: {
      date: {
        start: new Date(1975, ...defaultDateData)
      },
      headline: '1975',
      subHeadline:
        'Congress expands the VRA’s scope to protect language minorities from voting discrimination.',
      content:
        'This increases access to voting and the democratic process to “persons who are American Indian, Asian American, Alaskan Natives or Spanish heritage.”',
      rightPanel: <Image className="full-panel-image-cover" image={images.image1975} alt="Washington, DC: On the anniversary of the signing of the original voting rights act, president Ford in a Rose Garden ceremony 8/6, signed a slightly broadened 7 year extension of the legislation. Applauding as the president signs are (left to right), Sen. Edward Kennedy, D-Mass.; Sen. Charles McC. Mathias, R-Md.; Rep Robert Kastenmier, D-Wisc.; and Vice President Nelson Rockefeller." showAria />
    },
    date_4: {
      date: {
        start: new Date(1976, ...defaultDateData)
      },
      headline: '1976',
      subHeadline:
        'State felony disenfranchisement laws exclude 1.17 million citizens from voting throughout the U.S.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image1976} alt="TENNESSEE COLONY, TX - JUNE 11: Scenes from life on an administrative segregation wing of the Hughes Unit, a Texas state prison, on June 1, 1997 outside of Gatesville, Texas. Suspected or actual gang members can be placed in the maximum security segregated wings of the Texas prison system for years, where they are not allowed contact with other prisoners and must be escorted by correction officers for a shower or exercise. Otherwise they spend at least 23 hours of each day in a solitary cell. (Photo by Andrew Lichtenstein/ Corbis via Getty Images)" showAria/>
    },
    date_5: {
      date: {
        start: new Date(1982, ...defaultDateData)
      },
      headline: '1982',
      subHeadline:
        'Congress renews the Voting Rights Act by 389-24 in the House and by 85-8 in the Senate.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image1982} alt='Washington: President Reagan signed an extension of the 1965 Voting Rights Act, saying "the right to vote is the crown jewel of American liberties." In the background are (L to R) Rep. Robert Michel, R-Ill., Sen. Orrin Hatch, R-Utah, and Sen. Robert Dole.' showAria />
    },
    date_6: {
      date: {
        start: new Date(1992, ...defaultDateData)
      },
      headline: '1992',
      subHeadline:
        'Congress renews the Voting Rights Act to strengthen language minorities, including Native American language speakers.',
      content:
        'It passes by 237-125 votes in the House, and by 75-20 in the Senate. <br /><br />Carol Moseley Braun of Illinois becomes the first ever African American Democrat (and the first African American woman) elected to the U.S. Senate ',
      rightPanel: <Image className="full-panel-image-cover" image={images.image1992} alt="U.S. President George H. Bush addresses a joint session of Congress on Capitol Hill in Washington, DC, during his State of the Union speech, Tuesday, January 29, 1991. (AP Photo/Ron Edmonds)Human Rights Campaign Hosts Presidential Candidates In Forum On Gay Issues?WASHINGTON - JULY 15: Democratic presidential candidate Carol Moseley Braun speaks during a forum on the candidate's position on issues of importance to the gay, lesbian, bisexual and transgender community July 15, 2003 in Washington, DC. Braun supportes same sex marriage. (Photo by Alex Wong/Getty Images)" showAria/>
    },
    date_7: {
      date: {
        start: new Date(2000, ...defaultDateData)
      },
      headline: '2000',
      subHeadline:
        'Across the South, African American voter registration is 65%, only 3% higher than it was in 1968.',
      content:
        'James Perkins, Jr. becomes the first African American elected mayor of Selma, Alabama. <br /><br />The 2000 presidential election is extremely close. Democrat Al Gore wins the national popular vote, but Republican George W. Bush wins the Electoral College after the Supreme Court ends a recount of ballots in Florida with Bush ahead by 537 votes.<br /><br />The closeness of the race ushers in a new national debate on the importance of voting rights.',
      rightPanel: (
        <Image className="full-panel-image-cover" image={images.image2000} alt='US Supreme Court Begins New Hand Count Hearing383106 08: Gore and Bush supporters face off during a protest December 11, 2000 outside the US Supreme Court in Washington. The U.S. Supreme Court began hearing arguments Monday in a case that could decide the next president, with lawyers for Republican George W. Bush and Democrat Al Gore disagreeing over whether hand counts of Florida ballots should resume. (Photo by Alex Wong/Newsmakers)""Gateway Educational Foundation/Institute Hosts" "Passing The Torch - From Selma To Today" Documentary ScreeningLOS ANGELES, CA - FEBRUARY 22: Mayor James Perkins speaks onstage during "Passing The Torch From Selma To Today" documentary screening at Skirball Cultural Center on February 22, 2018 in Los Angeles, California. (Photo by Maury Phillips/Getty Images)Republican presidential candidate Texas Gov. George W. Bush and Democratic presidential candidate Vice President Al Gore shake hands before the first presidential debate Tuesday, Oct. 3, 2000, at the Clark Athletic Center on the campus of the University of Massachusetts-Boston. (AP Photo/Ron Edmonds)' showAria />
      ),
      modal: simpleModal(
        'Florida had the lowest percentage of African American voters registered in the South.',
        'At 53% it was lower than before the VRA in 1964 (64%). <br /><br />Some members of Congress highlighted the role of voter disenfranchisement that disproportionately targeted African Americans in this election, especially in Florida.'
      )
    },
    date_8: {
      date: {
        start: new Date(2006, ...defaultDateData)
      },
      headline: '2006',
      subHeadline:
        'A bipartisan vote in Congress renews the VRA for the fifth time.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image2006} alt='USA - Bush - Voting Rights Act of 2006 - SigningPresident George W Bush (C) signs the Voting Rights Act of 2006 during a ceremony on the South Lawn of the White House in Washington, July 27, 2006. Witnessing the ceremony are (L-R) Rep. John Conyers (D-Michigan), Rep. James Sensenbrenner (R-Wisconsin), House Minority Leader Nancy Pelosi of California, Senate Minority Leader Harry Reid of Nevada, and Senate Majority Leader Bill Frist of Tennessee. The law extends the original Voting Rights Act by 25 years, which opened polls to millions of black Americans by outlawing racist voting practices in the South. (Photo by Brooks Kraft LLC/Corbis via Getty Images)' showAria/>
    },
    date_9: {
      date: {
        start: new Date(2008, ...defaultDateData)
      },
      headline: '2008',
      subHeadline:
        'Barack Obama (Democrat-Ill.) became the first African American elected President, winning the electoral vote by 365 to 173.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image2008} alt='Barack Obama Campaigns In IowaPELLA, IA - JULY 4: Democratic presidential hopeful Barack Obama answers questions during a news conference in a backyard July 4, 2007 in Pella, Iowa. The U.S. senator from Illinois, who is on a two-day campaign swing through Iowa, recently announced that his campaign had raised $32.5 million in the second quarter, outpacing rival Sen. Hillary Clinton (D-NY), who pulled in $20 million. (Photo by David Lienemann/Getty Images)' showAria/>
    },
    date_10: {
      date: {
        start: new Date(2010, ...defaultDateData)
      },
      headline: '2010',
      content:
        '<span class="bullet">The number of disenfranchised felons has grown five-fold since 1976 to 5.9 million as a result of policies leading to mass incarceration.</span><span class="bullet">James Bonard Fowler was indicted and sentenced to 6 months in prison for "second degree manslaughter" of Jimmie Lee Jackson—45 years after the crime was committed.</span>',
      rightPanel: <Image className="full-panel-image-cover" image={images.image2010A} alt="Supreme Court To Rule On California's Overcrowded PrisonsCHINO, CA - DECEMBER 10: Inmates at Chino State Prison, which houses 5500 inmates, crowd around double and triple bunk beds at a gymnasium that was modified to house 213 prisoners on December 10, 2010 in Chino, California. The U.S. Supreme Court is preparing to hear arguments to appeal a federal court's ruling last year that the California state prison system would have to release 40,000 prisoners to cope with overcrowding so severe that it violated their human rights. More than 144,000 inmates are currently incarcerated in prisons that were designed to hold about 80,000. (Photo by Kevork Djansezian/Getty Images)ADVANCE FOR SUNDAY, DEC. 26TH AND THEREAFTER AS DESIRED FOR USE WITH YEAR END STORIESFILE - In this Aug. 17, 2010 file photo, former Alabama State Trooper James Bonard Fowler sits in the Perry County Courthouse prior to a hearing in Marion, Ala. State authorities closed the books on an infamous civil rights slaying when Fowler, 77, pleaded guilty and received a six-month jail sentence in the slaying of Jimmie Lee Jackson after a voting rights march in Marion in 1965. The Fowler story is the No. 6 story of 2010 as ranked by the Alabama staff of The Associated Press. (AP Photo/Dave Martin, File)" showAria />
    },
    date_11: {
      date: {
        start: new Date(2012, ...defaultDateData)
      },
      headline: '2012',
      subHeadline: 'Barack Obama is re-elected.',
      content:
        'For the first time since the passage of the VRA, African American voter turnout nationwide (67% of registered voters) exceeds white turnout (64%).',
      rightPanel: <Image className="full-panel-image-cover" image={images.image2012} alt="Election night with Obama in ChicagoCHICAGO, IL - NOVEMBER 06: President Barack Obama and his family and the Biden family celebrate victory on election night at McCormick Place with supporters, on November 6, 2012 in Chicago, Illinois. Obama beat Republican candidate and former Massachusetts governor Mitt Romney to get re-elected for four more years. (Photo by Melanie Stetson Freeman/The Christian Science Monitor via Getty Images)" showAria/>
    },
    date_12: {
      date: {
        start: new Date(2013, ...defaultDateData)
      },
      headline: '2013',
      content:
        'In a 5-4 decision in Shelby v. Holder, the US Supreme Court narrowly strikes down the provisions of the VRA that required states and communities with a prior history of racial discrimination to seek “preclearance” from the federal government before changing any electoral laws.',
      component: (
        <ScrollContainer className="timeline-1965-present__2013">
          <div className="timeline-1965-present__2013__section">
            <div className="timeline-1965-present__2013__section__text" >
              <h1 tabIndex="0">2013</h1>
              <h2 tabIndex="0">
                In a 5-4 decision in Shelby v. Holder, the US Supreme Court
                narrowly strikes down the provisions of the VRA that required
                states and communities with a prior history of racial
                discrimination to seek “preclearance” from the federal government
                before changing any electoral laws.
              </h2>
              <p className="alt" tabIndex="0">
                Many observers view the Shelby case as a green light to states and
                other jurisdictions to enact voter ID laws that would restrict
                voting rights.{' '}
              </p>
            </div>
            <div className="timeline-1965-present__2013__section__image">
              <div className="timeline-1965-present__2013__section__image__container">
                <Image image={images.image2013A} alt="US-VOTE-RIGHTS-JUSTICEActivists hold a pro-voting rights placards outside of the US Supreme Court on February 27, 2013 in Washington, DC as the Court prepares to hear Shelby County vs Holder. The case centers around a key section of the 1965 Voting Rights Act which mandates federal approval for any proposed voting changes in nine states. AFP PHOTO/Mandel NGAN (Photo credit should read MANDEL NGAN/AFP/Getty Images)" showAria/>
              </div>
            </div>
          </div>
          <div className="timeline-1965-present__2013__section">
            <div className="timeline-1965-present__2013__section__text">
              <Quote
                quote="The conditions that originally justified these measures no longer characterize voting in the covered jurisdictions…these improvements are in large part because of the Voting Rights Act"
                name="Chief Justice John Roberts"
                title="for the majority"
                position={1}
              />
            </div>
            <div className="timeline-1965-present__2013__section__image">
              <div className="timeline-1965-present__2013__section__image__container">
                <Image image={images.image2013B} alt="John Roberts.Photograph of John Roberts (1955-) Chief Justice of the Supreme Court. Dated 21st Century. (Photo by: Universal History Archive/UIG via Getty Images)" showAria />
              </div>
            </div>
          </div>
          <div className="timeline-1965-present__2013__section">
            <div className="timeline-1965-present__2013__section__text">
              <Quote
                quote="[T]hrowing out preclearance when it has worked and is continuing to work to stop discriminatory changes is like throwing away your umbrella in a rainstorm because you are not getting wet."
                name="Justice Ruth Bader Ginsburg"
                title="in dissent"
                position={1}
              />
            </div>
            <div className="timeline-1965-present__2013__section__image">
              <div className="timeline-1965-present__2013__section__image__container">
                <Image image={images.image2013C} alt="Ruth Bader Ginsburg At The Supreme CourtSupreme Court Justice Ruth Bader Ginsburg is photographed in the Supreme Court building in Washington, DC, July 27, 2012. (Photo by Lynsey Addario/Getty Images)" showAria />
              </div>
            </div>
          </div>
        </ScrollContainer>
      )
    },
    date_13: {
      date: {
        start: new Date(2013, ...defaultDateData),
        end: new Date(2018, ...defaultDateData)
      },
      headline: '2013-2018',
      subHeadline:
        'The debate over voting rights in the US grows increasingly partisan.',
      content:
      'Republicans focus on issues of "voter fraud" that they say can be prevented by stricter voter ID laws.<br></br> Democrats argue that voter fraud is not a significant problem and claim that several new state election laws in Republican-controlled states are intended to disenfranchise people of color, non-English speakers, the poor, and younger voters.',
      rightPanel: <Image className="full-panel-image-cover" image={images.image20132018} alt="North Carolina Voters Go To The Polls In State PrimaryRALEIGH, NC - MARCH 15: North Carolina State University students wait in line to vote in the primaries at Pullen Community Center on March 15, 2016 in Raleigh, North Carolina. The North Carolina primaries is the state's first use of the voter ID law, which excludes student ID cards. Wake County was among the highest use of provisional ballots, where those voters had home addresses on or near campuses. The Board of Elections will review voter's reasonable impediment form submitted with their provisional ballots to determine if their vote counts. The state's voter ID law is still being argued in federal court. (Photo by Sara D. Davis/Getty Images )" showAria />
    },
    date_14: {
      date: {
        start: new Date(2016, ...defaultDateData)
      },
      headline: '2016',
      subHeadline:
        'State felony disenfranchisement laws exclude more than 6 million US citizens from voting. ',
      content:
        'For the highest felony disenfranchisement rates are in the former Confederate and Southern states that disenfranchised voters on the basis of race before the 1965 Voting Rights Act.',
      rightPanel: <Image className="fill-height" image={images.image2016} />
    },
    date_15: {
      date: {
        start: new Date(2018, ...defaultDateData),
        giveStartPriority: true
      },
      headline: '2018',
      subHeadline:
        'A state ballot measure in Florida restores the voting rights of one million former felons who have completed their prison sentences and probation. ',
      rightPanel: <Image className="fill-height" image={images.image2018A} alt="FORT MYERS, FLORIDA - JANUARY 08: Neil Volz (L) and Lance Wissinger prepare to fill out their voter registration forms at the Lee Country Supervisor of Elections office on January 08, 2019 in Fort Myers, Florida. Mr. Wissinger and Mr. Volz, both with felony records, are now able to register to vote for the first time after a new constitutional amendment took effect, which automatically restores voting rights to most people who have felonies on their record. The referendum overturned a 150-year-old law that barred people with felony convictions from voting. (Photo by Joe Raedle/Getty Images)Former felon Desmond Meade and president of the Florida Rights Restoration Coalition, left, arrives with family members at the Supervisor of Elections office Tuesday, Jan. 8, 2019, in Orlando, Fla., to register to vote. Former felons in Florida began registering for elections on Tuesday, when an amendment that restores their voting rights went into effect. (AP Photo/John Raoux)" showAria/>
    }
  }
};

const TimelinePost1965 = class extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.dateData = getDataContents(props.data);
  }
  componentDidMount() {
    this.context.setTheme('dark')
  }

  renderSlideContent(id) {
    const dateContents = this.dateData

    return (
      <SplitView
        key={id}
        viewType={viewTypes.EVEN}
        inheritHeight={true}
        leftPanel={
          dateContents[id].leftPanel || (
            <ScrollContainer className="slide-container">
              <div className="slide-content" {...tabbable}>
                <h1>{dateContents[id].headline}</h1>
                <h2>{dateContents[id].subHeadline}</h2>
                <p
                  dangerouslySetInnerHTML={{ __html: dateContents[id].content }}
                />
                {dateContents[id].modal &&
                  this.createModal(dateContents[id].modal)}
              </div>
            </ScrollContainer>
          )
        }
        rightPanel={dateContents[id].rightPanel}
      />
    );
  }

  componentDidMount() {
    this.context.setTheme('dark')
  }


  render() {
    const { progress } = this.props;
    const dateContents = this.dateData
    return (
      <div className="standard-interactive-timeline timeline-1965-present">
        <InteractiveTimeline
          progress={progress}
          dates={Object.keys(dateContents).map(dateKey => {
            const {
              headline,
              date,
              subHeadline,
              component,
              content
            } = dateContents[dateKey];

            return {
              label: headline,
              slug: dateKey,
              date: date,
              contents: [subHeadline || content],
              component: component || this.renderSlideContent(dateKey)
            };
          })}
        />
      </div>
    );
  }

  createModal(children) {
    return (
      <Modal
        className="timeline-1965-present__modal"
        stickyPosition={stickyPositions.NO_STICKY}
        modalBackground={modalBackgrounds.WHITE}>
        {children}
      </Modal>
    );
  }
};


const query = graphql`
  query {
    image1965: file(relativePath: { eq: "1965-present/img/ch7-image-1965.jpg" }) {
      ...largeImage
    }
    image1970: file(relativePath: { eq: "1965-present/img/ch7-image-1970.jpg" }) {
      ...largeImage
    }
    image1975: file(relativePath: { eq: "1965-present/img/ch7-image-1975.jpg" }) {
      ...largeImage
    }
    image1976: file(relativePath: { eq: "1965-present/img/ch7-image-1976.jpg" }) {
      ...largeImage
    }
    image1982: file(relativePath: { eq: "1965-present/img/ch7-image-1982.jpg" }) {
      ...largeImage
    }
    image1992: file(relativePath: { eq: "1965-present/img/ch7-image-1992.jpg" }) {
      ...largeImage
    }
    image2000: file(relativePath: { eq: "1965-present/img/ch7-image-2000.jpg" }) {
      ...largeImage
    }
    image2006: file(relativePath: { eq: "1965-present/img/ch7-image-2006.jpg" }) {
      ...largeImage
    }
    image2008: file(relativePath: { eq: "1965-present/img/ch7-image-2008.jpg" }) {
      ...largeImage
    }
    image2010A: file(relativePath: { eq: "1965-present/img/ch7-image-2010.jpg" }) {
      ...largeImage
    }
    image2012: file(relativePath: { eq: "1965-present/img/ch7-image-2012.jpg" }) {
      ...largeImage
    }
    image2013B: file(relativePath: { eq: "1965-present/img/ch7-image-2013-john_roberts.jpg" }) {
      ...largeImage
    }
    image2013A: file(relativePath: { eq: "1965-present/img/ch7-image-2013-protect_my_vote.jpg" }) {
      ...largeImage
    }
    image2013C: file(relativePath: { eq: "1965-present/img/ch7-image-2013-ruth_ginsburg.jpg" }) {
      ...largeImage
    }
    image2016: file(relativePath: { eq: "1965-present/img/ch7-image-2016.jpg" }) {
      ...largeImage
    }
    image2018A: file(relativePath: { eq: "1965-present/img/ch7-image-2018.jpg" }) {
      ...largeImage
    }
    image20132018: file(relativePath: { eq: "1965-present/img/ch7-image-2013_2018.jpg" }) {
      ...largeImage
    }
  }
`;


const Component = timeline(TimelinePost1965);
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
