import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax from 'gsap/TweenMax';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import scene from 'components/scene';
import {
  showElemWithAria, hideElementWithAria
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-13.scss';
import {TimelineMarkers} from 'utils/scene'

const C7S13 = class extends React.Component {
  render() {
    const {
      doodleX,
      map,
      crowd,
      clapping,
    } = this.props.data;

    return (
      <FullScreenImage
        className="chapter-7-scene-13"
        image={map.childImageSharp.fluid.src}
        aspectRatio={8 / 5}>
        <div className="chapter-7-scene-13__path">
          <div className="line" ref="line" />
          <div ref="doodleX">
            <Image image={doodleX} />
          </div>
        </div>
        <div ref="clapping" {...tabbable} className="chapter-7-scene-13__clapping">
          <Image className="clapping" image={clapping} sizes="100vw" alt="image of marcher clapping" showAria/>
        </div>
        <div ref="crowd" className="chapter-7-scene-13__crowd">
          <div className="chapter-7-scene-13__crowd__overlay">
            <svg viewBox="0 0 100 62.5" preserveAspectRatio="none">
              <defs>
                <radialGradient id="crowdMaskGradient">
                  <stop offset="10%" stopColor="white" />
                  <stop ref="stop" offset="95%" stopColor="black" />
                </radialGradient>

                <mask id="crowdMask">
                  <circle
                    ref="circleMask"
                    cx="48%"
                    cy="48%"
                    r="15%"
                    fill="url('#crowdMaskGradient')"
                  />
                </mask>
              </defs>
              <image
                ref="crowdImage"
                {...tabbable}
                aria-label="Aerial View Of Civil Rights Marchers
                Civil rights marchers arrive at the Alabama State Capitol in Montgomery, Alabama after a 50 mile march from Selma to protest race discrimination in voter registration."
                preserveAspectRatio="xMinYMin slice"
                xlinkHref={crowd.childImageSharp.fluid.src}
                width="100%"
                height="100%"
                mask="url(#crowdMask)"
              />
            </svg>
          </div>
        </div>
      </FullScreenImage>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {
    crowd,
    crowdImage,
    stop,
    circleMask,
    clapping,
    doodleX,
    line,
  } = context.refs;


  TweenMax.set([clapping], { ...showElemWithAria, scale: 1.1 });
  TweenMax.set([doodleX], { ...hideElementWithAria });
  TweenMax.set([line], { width: '0%' });

  timeline.to(clapping, 3.5, { scale: 1, ease: Quad.easeIn });
  timeline.to(clapping, 1, { ...hideElementWithAria }, "-=1");

  TweenMax.set(crowd, { z: 300, ...hideElementWithAria });
  TweenMax.set(crowdImage, { x: '0%', y: '0%' });
  TweenMax.set(stop, { attr: { 'stop-color': 'black' } });
  TweenMax.set(circleMask, { attr: { r: '10%' } });

  timeline.to(line, 1.5, { width: '45%', ...easeOut });
  timeline.to(doodleX, 1, { ...showElemWithAria }, '-=1');

  timeline.to(crowd, 0.75, { ...showElemWithAria });
  timeline.to(crowd, 2, { z: 0, ease: Expo.easeInOut }, "-=0.25");
  timeline.to(crowdImage, 2, { x: '0%', y: '0%', ease: Ease.easeInOut }, '-=2');
  timeline.to(
    circleMask,
    2,
    { attr: { r: '100%' }, ease: Expo.easeInOut },
    '-=2'
  );
  timeline.to(stop, 1, { attr: { 'stop-color': 'white' } }, '-=1');


};

const query = graphql`
  query {
    doodleX: file(relativePath: { eq: "chapter-7/scene-13/images/doodle-x.png" }) {
      ...doodleImage
    }
    map: file(relativePath: { eq: "chapter-7/scene-13/images/ch-7-image-montgomery-map-end@2x.jpg" }) {
      ...largeImage
    }
    crowd: file(relativePath: { eq: "chapter-7/scene-13/images/ch7-image-montgomery_gathering.jpg" }) {
      ...largeImage
    }
    clapping: file(relativePath: { eq: "chapter-7/scene-13/images/ch-7-image-clapping.jpg" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S13, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
