import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FullScreenContainer from 'components/FullScreenContainer';
import {
  hideFromBelow, revealFromBelow
} from 'utils/animation';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import scene from 'components/scene';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-11.scss';

const C7S11 = class extends React.Component {
  render() {
    const {montgomery} = this.props.data;

    return (
      <FullScreenContainer className="chapter-7-scene-11">
        <div className="chapter-7-scene-11__image">
          <Image image={montgomery} sizes="100vw" showAria alt="Downtown Montgomery Elevated view along Dexter Avenue, as seen from the Exchange Hotel, Montgomery, Alabama, March 24, 1965. The following day, the Selma to Montgomery March arrived at the Alabama State Capitol, in the distance. In the foreground is the 'Historic Artesian Basin' where several segregationists display American and Confederate flags. (Photo by Charles Shaw/Getty Images)" />
        </div>

        <div {...tabbable} className="chapter-7-scene-11__text">
          <p ref="text1">Montgomery is eerily quiet.</p>
          <p ref="text2">Governor Wallace has urged white Alabamians to stay home, proclaiming a “danger holiday” for the state’s white female employees.</p>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const { text1, text2 } = context.refs;

  TweenMax.set([text1, text2], {...hideFromBelow})

  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
};

const query = graphql`
  query {
    montgomery: file(relativePath: { eq: "chapter-7/scene-11/images/ch-7-image-montgomery-quiet@2x.jpg" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S11, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
