import React from 'react';
import TweenMax from 'gsap/TweenMax';
import {useStaticQuery, graphql} from 'gatsby';
import {
  showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import PathReveal from 'components/PathReveal';
import {getPercentageBetweenBounds} from 'utils';
import scene from 'components/scene';
import Image from 'components/Image';
import {TimelineMarkers} from 'utils/scene'
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-8.scss';

const C7S8 = class extends React.Component {
  render() {
    const {position} = this.props;
    const {
      map,
      photo,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-7-scene-8">
        <div ref="zoom">

          <FullScreenImage image={map.childImageSharp.fluid.src} aspectRatio={8 / 5}>
            <div ref="photo" className="chapter-7-scene-8__photo">
            <Image image={photo} sizes="50vw" showAria alt="USA. Selma, Alabama. 1965. The Great Freedom March. Martin Luther King Jr. led a group from Selma to Montgomery. From the beginning of the march in Selma, the marchers found jeering bystanders."/>
            </div>
            <div className="chapter-7-scene-8__path">
              <PathReveal
                position={getPercentageBetweenBounds(position, 0, 1)}
                constant={140}
                strokeWidth={17}
                viewBox="0 0 1440 900"
                path="M0,76.29c0,0,60.52,5.58,86.19,8.93s74.79,6.7,87.07,10.05c12.28,3.35,45.77,21.21,61.4,31.26
              s39.07,24.56,60.28,31.26s40.19,13.4,120.56,25.67c80.37,12.28,161.86,27.91,184.19,33.49c22.33,5.58,54.7,27.91,88.19,32.37
              c33.49,4.47,186.42-5.58,277.95,45.77s121.67,97.12,160.74,129.49s32.37,39.07,104.93,55.81S1440,538.43,1440,538.43"
              />
            </div>
          </FullScreenImage>
        </div>

        <div ref="text" className="chapter-7-scene-8__text">
          <p {...tabbable} className="alt">
            The group is approximately 2,000-strong when they leave Selma, but chose to
            reduce in size while in Lowndes County due to the danger of attack.
          </p>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const { photo, text, zoom } = context.refs;

  TweenMax.set(text, {...hideFromBelow})
  TweenMax.set(zoom, {scale: 1.4, ...hideElementWithAria});
  TweenMax.set(photo, { y: window.innerHeight, rotation: '45deg' });

  timeline.to(zoom, 4, { scale: 1, ease: Quad.easeOut });
  timeline.to(zoom, 1, { ...showElemWithAria }, "-=4");
  timeline.to(text, 1.5, {...revealFromBelow, ...easeOut}, "-=3")
  timeline.to(photo, 1.5, { y: 0, rotation: '0deg', ...easeOut }, "-=2");

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    map: file(relativePath: { eq: "chapter-7/scene-8/images/ch-7-image-selma-map-red-lines@2x.jpg" }) {
      ...largeImage
    }
    photo: file(relativePath: { eq: "chapter-7/scene-8/images/ch-7-image-threatening-group@2x.png" }) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(C7S8, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
