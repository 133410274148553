import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo} from 'gsap/TweenMax';
import {getPercentageBetweenBounds} from 'utils';
import SplitView from 'templates/SplitView';
import FullScreenContainer from 'components/FullScreenContainer';
import {TimelineMarkers} from 'utils/scene';
import timeline from 'components/timelineComponent';
import HorizontalShift from 'components/HorizontalShift';
import scene from 'components/scene';
import Image from 'components/Image';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-2.scss';

const sceneShiftStart = 0;
const sceneShiftEnd = 1;

const C7S2 = class extends React.Component {
  render() {
    const {
      background,
      guardsA,
      guardsB,
    } = this.props.data;

    const {position} = this.props;
    const splitViewProgress = getPercentageBetweenBounds(position, sceneShiftStart, sceneShiftEnd);

    return (
      <FullScreenContainer className="chapter-7-scene-2">
        <HorizontalShift
          position={splitViewProgress}
          screens={[
            (
              <div className="chapter-7-scene-2__left">
                <SplitView
                  leftPanel={
                    <div className="chapter-7-scene-2__text">
                    </div>
                  } />
              </div>
            ),
            (
              <div className="chapter-7-scene-2__right">
                <SplitView
                  fullWidthPanel={
                    <div ref="background" className="chapter-7-scene-2__background">
                      <Image image={background} sizes="125vw" alt="guards protecting march"/>
                      <div ref="imageA">
                        <Image image={guardsA} sizes="125vw" alt="guards in black"/>
                      </div>
                      <div ref="imageB">
                        <Image image={guardsB} showAria sizes="125vw" alt="National Guard troops line the route of the Selma to Montgomery Civil Rights March, which protested the denial of voting rights to African Americans. The National Guard was ordered to ensure the safety of the marchers after two previous march attempts were plagued by violent attacks by the local Sheriff, police department and white supremacists. (Photo by Steve Schapiro/Corbis via Getty Images)"/>
                      </div>

                    </div>
                  }
                  rightPanel={
                    <div {...tabbable} className="chapter-7-scene-2__text">
                      <p>He orders Governor Wallace to <span className="highlight">protect</span> the marchers.
                      </p>
                    </div>
                  } />
              </div>
            ),
          ]} />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline}) => {


  const {background, imageB} = context.refs;
  TweenMax.set(background, {x: '-25%'});
  TweenMax.set(imageB, {...hideElementWithAria});

  timeline.to(background, 3, {x: '0%', ease: Expo.easeInOut});
  timeline.to(imageB, 1, {...showElemWithAria}, '-=3');

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-2/images/ch-7-image-marchers-protected@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 3061) {
          ...selmaFluidImage
        }
      }
    }
    guardsA: file(relativePath: { eq: "chapter-7/scene-2/images/ch-7-image-national-guard@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 3061) {
          ...selmaFluidImage
        }
      }
    }
    guardsB: file(relativePath: { eq: "chapter-7/scene-2/images/ch-7-image-national-guard-sepial@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 3061) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

const Component = scene(timeline(C7S2, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
