import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax from 'gsap/TweenMax';
import FullScreenImage from 'components/FullScreenImage';
import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import PathReveal from 'components/PathReveal';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene';
import {
  easeOutQuad, showElemWithAria, hideElementWithAria
} from 'utils/animation';

import './chapter-7-scene-10.scss';

const C7S10 = class extends React.Component {
  render() {
    const { position } = this.props;
    const {map, joy} = this.props.data;

    const start = 0.25
    const end = 1 - start
    const linePosition = start + (easeOutQuad(position) * end)

    return (
      <div ref="zoom">
        <FullScreenImage
          className="chapter-7-scene-10"
          image={map.childImageSharp.fluid.src}
          aspectRatio={8 / 5}>
          <div className="chapter-7-scene-10__path">
            <PathReveal
              position={linePosition}
              constant={73}
              strokeWidth={6}
              viewBox="0 0 1440 900"
              path="M0,586.8c0,0,16.24-1.12,24.05,2.23s9.67,4.09,23.81,7.81c14.14,3.72,31.26-0.74,46.14,7.44
              s28.65,19.35,34.6,24.56s24.93,9.67,37.58,12.28c12.65,2.6,15.26,2.98,23.44,8.56c8.19,5.58,20.47,12.28,46.88,15.26
              c26.42,2.98,34.6-0.37,50.98,4.47c16.37,4.84,41.49,9.67,54.33,10.23c12.84,0.56,44.65-6.7,64.19-7.81s11.72,0,35.16-3.91
              c23.44-3.91,7.26-8.37,41.86-5.02c34.6,3.35,61.95-3.35,76.46-5.58s25.67-10.05,37.4-18.98c11.72-8.93,23.44-18.42,35.16-19.53
              c11.72-1.12,9.49-15.63,6.14-25.67c-3.35-10.05-10.05-19.53,10.6-37.95s31.26-34.05,58.6-34.05c27.35,0,48.56-3.35,64.74,9.49"
            />
          </div>
        </FullScreenImage>
        <div ref="joy" className="chapter-7-scene-10__joy">
          <Image image={joy} sizes="100vw" showAria alt="Selma to Montgomery March for voting rights: 1965 ~ 1965-03-21 Marchers singing in rain. Doris Wilson in foreground." />
        </div>
      </div>
    );
  }
};

const animation = ({context, timeline}) => {
  const {zoom, joy} = context.refs;


  TweenMax.set(zoom, {...hideElementWithAria, scale: 1.3});
  TweenMax.set([joy], { ...showElemWithAria, scale: 1});

  timeline.to(zoom, 5, { scale: 1, ease: Quad.easeOut });
  timeline.to(zoom, 1, { ...showElemWithAria }, "-=5");
  timeline.to(joy, 1, { ...hideElementWithAria }, "-=3");


};

const query = graphql`
  query {
    map: file(relativePath: { eq: "chapter-7/scene-10/images/ch-7-image-map-montgomery-wide@2x.jpg" }) {
      ...largeImage
    }
    joy: file(relativePath: { eq: "chapter-7/scene-10/images/ch7-image-joyous_rain.jpg" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S10, {animation}), {stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
