import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import SplitView, {viewTypes} from 'templates/SplitView';
import timeline from 'components/timelineComponent';
import scene from 'components/scene';
import Image from 'components/Image';
import {
  hideFromBelow, revealFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-15.scss';

const C7S15 = class extends React.Component {
  render() {
    const {line} = this.props.data;

    return (
      <SplitView
        className="chapter-7-scene-15"
        viewType={viewTypes.ONE_THIRD}
        fullWidthPanel={
          <Image className="chapter-7-scene-15__background" image={line} sizes="100vw" alt="Black voters line up at a general store to vote for the first time in rural Greene County. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)" showAria />
        }
        leftPanel={
          <div {...tabbable} className="chapter-7-scene-15__text">
            <h1 ref="text1">1965</h1>
            <p ref="text2">By December 1965, the number of registered black voters in Dallas County has risen from 383 to about 9,000—</p>
            <p ref="text3"className="alt">slightly more than 50% of the black voting age population.</p>
            <p ref="text4">Throughout Alabama, <span className="highlight">thousands</span> register for the first time.</p>
          </div>
        }
        rightPanel={
          <div className="chapter-7-scene-15__graph">
            <div className="chapter-7-scene-15__graph__top">
              <div className="label red">
                9000 Registered
              </div>
              <div className="line">
                <svg width="100%" height="60vh">
                  <line x1="0" y1="100%" x2="100%" y2="0" className="vector-line"/>
                </svg>
              </div>
              <div className="label white">
                383 Registered
              </div>
            </div>
            <ul className="chapter-7-scene-15__graph__bottom">
              <li>Aug</li>
              <li>Sep</li>
              <li>Oct</li>
              <li>Nov</li>
              <li>Dec</li>
            </ul>
            <div className="chapter-7-scene-15__graph__caption">
              Voting Rights Act passes
            </div>
          </div>
        }
      />
    );
  }
};

const query = graphql`
  query {
    graph: file(relativePath: { eq: "chapter-7/scene-15/images/ch-7-graph-registered-line@2x.png" }) {
      ...mediumImage
    }
    line: file(relativePath: { eq: "chapter-7/scene-15/images/ch-7-image-voting-registration-line@2x.jpg" }) {
      ...largeImage
    }
  }
`;

const animation = ({context, timeline, easeOut}) => {
  const { text1, text2, text3, text4 } = context.refs;

  TweenMax.set([text1, text2, text3, text4], {...hideFromBelow})

  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.5")
  timeline.to(text3, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
  timeline.to(text4, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")
};

const Component = scene(timeline(C7S15, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
