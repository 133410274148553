import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import ChapterPage from 'components/chapter';

import ChapterIntro from 'templates/ChapterIntro';
import ChapterConclusion from 'components/ChapterConclusion';

import Chapter7Scene1 from 'components/scenes/chapter-7/scene-1/Chapter7Scene1';
import Chapter7Scene2 from 'components/scenes/chapter-7/scene-2/Chapter7Scene2';
import Chapter7Scene3 from 'components/scenes/chapter-7/scene-3/Chapter7Scene3';
import Chapter7Scene4 from 'components/scenes/chapter-7/scene-4/Chapter7Scene4';
import Chapter7Scene4b from 'components/scenes/chapter-7/scene-4b/Chapter7Scene4b';
import Chapter7Scene5 from 'components/scenes/chapter-7/scene-5/Chapter7Scene5';
import Chapter7Scene6 from 'components/scenes/chapter-7/scene-6/Chapter7Scene6';
import Chapter7Scene7 from 'components/scenes/chapter-7/scene-7/Chapter7Scene7';
import Chapter7Scene8 from 'components/scenes/chapter-7/scene-8/Chapter7Scene8';
import Chapter7Scene9 from 'components/scenes/chapter-7/scene-9/Chapter7Scene9';
import Chapter7Scene10 from 'components/scenes/chapter-7/scene-10/Chapter7Scene10';
import Chapter7Scene11 from 'components/scenes/chapter-7/scene-11/Chapter7Scene11';
import Chapter7Scene12 from 'components/scenes/chapter-7/scene-12/Chapter7Scene12';
import Chapter7Scene13 from 'components/scenes/chapter-7/scene-13/Chapter7Scene13';
import Chapter7Scene14 from 'components/scenes/chapter-7/scene-14/Chapter7Scene14';
import Chapter7Scene15 from 'components/scenes/chapter-7/scene-15/Chapter7Scene15';
import Chapter7Scene16 from 'components/scenes/chapter-7/scene-16/Chapter7Scene16';
import Chapter7Scene17 from 'components/scenes/chapter-7/scene-17/Chapter7Scene17';
import Chapter7Scene18 from 'components/scenes/chapter-7/scene-18/Chapter7Scene18';
import Chapter7Scene19 from 'components/scenes/chapter-7/scene-19/Chapter7Scene19';
import Chapter7Scene20 from 'components/scenes/chapter-7/scene-20/Chapter7Scene20';
import {Chapter7Qa} from 'components/scenes/chapter-7/scene-20/Chapter7Qa';
export class Chapter7 extends React.Component {
  render() {
    const {data} = this.props;
    const {endImage, heroImage} = data;

    const title = 'The Right To Vote'
    return (
      <div className="chapter-7">
        <ChapterPage
          chapterNumber={7}
          title={title}
          scenes={[
            <ChapterIntro
              title={'Chapter 7'}
              subtitle={title}
              hero={heroImage}
              alt="Civil rights leader Dr. Martin Luther King addresses a huge gathering following their aborted march from Selma to Montgomery, Alabama to point out the lack of voter registration opportunity for African Americans."
            />,
            <Chapter7Scene1 />,
            <Chapter7Scene2 />,
            <Chapter7Scene3 />,
            <Chapter7Scene4 />,
            <Chapter7Scene4b theme="dark"/>,
            <Chapter7Scene5 />,
            <Chapter7Scene6 />,
            <Chapter7Scene7 />,
            <Chapter7Scene8 />,
            <Chapter7Scene9 />,
            <Chapter7Scene10 />,
            <Chapter7Scene11 theme="dark"/>,
            <Chapter7Scene12 />,
            <Chapter7Scene13 />,
            <Chapter7Scene14 />,
            <Chapter7Scene16 />,
            <Chapter7Scene15 />, // Only swapped
            <Chapter7Scene17 />,
            <Chapter7Scene18 />,
            <Chapter7Scene19 />,
            <Chapter7Scene20 />,
            <ChapterConclusion
              image={endImage}
              questions={Chapter7Qa}
              chapterNumber={7}
              nextChapterTitle="Taking Action Today"
              continueCta="Continue to Conclusion"
              theme="dark"
            />,

          ]}
        />
      </div>
    );
  }
}

export const query = graphql`
  query {
      heroImage: file(relativePath: { eq: "chapter-7/ch-7-image-mlk-speaking@2x.jpg" }) {
        ...mediumImage
      }
      endImage: file(relativePath: { eq: "chapter-7/ch-7-image-obama-selma-bridge-fade@2x.jpg" }) {
        ...largeImage
      }
  }
`;

export default Chapter7;

Chapter7.propTypes = {
  SceneComponent: PropTypes.func,
  controller: PropTypes.object,
};
