import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo} from 'gsap/TweenMax';
import {numberWithCommas} from 'utils';
import SplitView, {viewTypes} from 'templates/SplitView';
import timeline from 'components/timelineComponent';
import scene from 'components/scene';
import Image from 'components/Image';
import {TimelineMarkers} from 'utils/scene';
import {
  hideElementWithAria, showElemWithAria
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-17.scss';

const C7S17 = class extends React.Component {
  render() {
    const {background} = this.props.data;

    return (
      <SplitView
        className="chapter-7-scene-17"
        viewType={viewTypes.ONE_THIRD}
        fullWidthPanel={
          <div ref="background">
            <Image
              className="chapter-7-scene-17__background"
              image={background}
              sizes="100vw"
              showAria
              alt="USA. Montgomery, Alabama. 1965. Civil rights demonstrators rally in Montgomery during the culmination of the Selma March."
            />
          </div>
        }
        leftPanel={
          <div {...tabbable} className="chapter-7-scene-17__text">
            <p>
              Across the nation, another 250,000 African American voters were
              added to the electoral register by the end of 1965.
            </p>
          </div>
        }
        rightPanel={
          <div ref="numberContainer" className="chapter-7-scene-17__number">
            <p ref="number">100</p>
          </div>
        }
      />
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const { number, numberContainer, background } = context.refs;


  const voters = {
    number: 250000
  };

  TweenMax.set(number, { y: window.innerHeight / 2, css: { color: 'white' } });
  TweenMax.set(numberContainer, { ...showElemWithAria, y: 0, scale: 1 });
  TweenMax.set(background, { opacity: 1 });

  timeline.from(numberContainer, 3, {
    y: window.innerHeight / 2,
    scale: 0.5,
    ...hideElementWithAria,
    ...easeOut
  });
  timeline.from(
    voters,
    2.25,
    {
      number: 100000,
      onUpdate: () => {
        number.innerHTML = numberWithCommas(voters.number.toFixed(0));
      },
      ease: Linear.easeNone
    },
    '-=2.75'
  );
  timeline.to(number, 1, { css: { color: 'red' } }, '-=2');
  timeline.to(background, 3, { opacity: 0.75 }, "-=3");


};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-17/images/ch7-image-crowd_waving_flags.jpg" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S17, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
