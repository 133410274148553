import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Image from 'components/Image';

import './chapter-7-scene-14.scss';

const CapitolGroundsModal = class extends React.Component {
  render() {
    const {Illustration} = this.props.data;

    return (
      <div className="chapter-7-scene-14__modal">
        <p className="chapter-7-scene-14__modal__heading">
          Despite the federal government's support for the march, Governor Wallace
          sees to it that MLK isn't allowed to deliver a speech on the Capitol
          grounds.
        </p>
        <div className="chapter-7-scene-14__modal__image">
          <Image image={Illustration} />
          <div className="chapter-7-scene-14__modal__image__overlay">
            <p className="label mlk">MLK</p>
            <p className="label capitol">Capitol Grounds Start</p>
            <div className="capitol-line" />
          </div>
        </div>
        <div className="chapter-7-scene-14__modal__areas">
          <div className="chapter-7-scene-14__modal__areas__area bainbridge">
            <div className="line" />
            <div className="label">Bainbridge Street</div>
            <div className="line" />
          </div>
          <div className="chapter-7-scene-14__modal__areas__area capitol">
            <div className="line" />
            <div className="label">Capitol Grounds - MLK NOT ALLOWED</div>
            <div className="line" />
          </div>
        </div>
        <div className="chapter-7-scene-14__modal__text">
          <p className="alt">
            State-police jurisdiction over the Capitol grounds begins at the curb
            closest to the Capitol steps, and 70 blue-helmeted state troopers had
            been deployed at the curb line of Bainbridge Street.
          </p>
          <p className="alt">
            They were backed by 50 uniformed conservation patrolmen, standing two
            deep halfway up the Capitol steps.
          </p>
        </div>
      </div>
    );
  }
};

const query = graphql`
  query {
    Illustration: file(relativePath: { eq: "chapter-7/scene-14/images/ch-7-illustration-mlk-stage@2x.png" }) {
      ...mediumImage
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <CapitolGroundsModal data={data} {...props} />;
};
