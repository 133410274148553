import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, { Expo, Linear } from 'gsap/TweenMax';
import {
  easeOutQuad, showElemWithAria, hideElementWithAria
} from 'utils/animation';
import SplitView, { viewTypes } from 'templates/SplitView';
import Quote from 'components/Quote';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import PathReveal from 'components/PathReveal';
import {getPercentageBetweenBounds} from 'utils';

import {TimelineMarkers} from 'utils/scene'
import scene from 'components/scene';

import './chapter-7-scene-5.scss';

const C7S5 = class extends React.Component {
  render() {
    const { position } = this.props;
    const {map} = this.props.data;

    const start = 0.3
    const end = 1 - start
    const linePosition = start + (position * end)

    return (
      <FullScreenContainer>
        <div ref="zoom">

          <FullScreenImage
            image={map.childImageSharp.fluid.src}
            aspectRatio={8 / 5}
            className="chapter-7-scene-5">
            <div className="chapter-7-scene-5__path">
              <PathReveal
                viewBox="0 0 1440 900"
                position={linePosition}
                constant={78}
                strokeWidth={5}
                path="M628.98,375.73l-27.63,14.23c0,0,20.93,35.16,24.28,49.4c3.35,14.23,29.3,121.4,34.33,144.84
              c5.02,23.44,8.37,37.67,64.46,59.44s90.42,40.19,112.19,57.77s82.88,79.53,102.98,99.63s53.58,57.77,61.12,66.14
              s25.52,11.72,82.25,18.42c56.73,6.7,92.73,14.41,92.73,14.41"
              />
            </div>
          </FullScreenImage>
        </div>
        <div className="chapter-7-scene-5__text">
          <Quote
            position={getPercentageBetweenBounds(position, 1/4, 3/4)}
            quote="We realized for the first time we were being protected by the government, actually being protected by the state instead of being beaten up by the state."
            name="Charles Mauldin"
            title="President: Dallas County Youth Voter’s League"
          />
        </div>
      </FullScreenContainer>
    );
  }
};


const animation = ({context, timeline}) => {
  const { zoom } = context.refs;


  TweenMax.set(zoom, {scale: 1.2, ...hideElementWithAria});
  timeline.to(zoom, 4, { scale: 1, ease: Quad.easeOut });
  timeline.to(zoom, 1, { ...showElemWithAria }, "-=4");

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    map: file(relativePath: { eq: "chapter-7/scene-5/images/ch-7-image-selma-map-wide@2x.jpg" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S5, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
