import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import PathReveal from 'components/PathReveal';
import timeline from 'components/timelineComponent';
import {
  easeOutQuad, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation'
import {TimelineMarkers} from 'utils/scene'
import scene from 'components/scene'
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-12.scss';

const C7S12 = class extends React.Component {
  render() {
    const {position} = this.props;
    const {map} = this.props.data;
    const start = 0.65
    const end = 1 - start
    const linePosition = start + (easeOutQuad(position) * end)

    return (
      <FullScreenContainer>
        <div ref="zoom">
          <FullScreenImage
            image={map.childImageSharp.fluid.src}
            aspectRatio={8 / 5}
            className="chapter-7-scene-12">
            <div className="chapter-7-scene-12__path">
              <PathReveal
                position={linePosition}
                constant={73}
                viewBox="0 0 1440 900"
                path="M335.12,900L546.1,671.26v-16.74L820.7,450l5.02,10.84c0,0,10.6,1.12,10.6,7.81l174.14-3.91"
              />
            </div>
          </FullScreenImage>
        </div>
        <div ref="text" className="chapter-7-scene-12__text">
          <p {...tabbable}>
            In the final four miles (from the St. Jude Roman Catholic high
            school where many marchers have camped, to the Alabama State
            Capitol) the group swells to 25,000, including local residents, SNCC
            and SCLC activists, and allies from across the nation.
          </p>
        </div>
      </FullScreenContainer>
    );
  }
};


const query = graphql`
  query {
    map: file(relativePath: { eq: "chapter-7/scene-12/images/ch-7-image-selma-map-wide@2x.jpg" }) {
      ...largeImage
    }
  }
`;

const animation = ({context, timeline, easeOut}) => {
  const {zoom, text} = context.refs;
  ;

  TweenMax.set(zoom, {scale: 1.4, ...hideElementWithAria});
  TweenMax.set(text, {...hideFromBelow})

  timeline.to(zoom, 4, { scale: 1, ease: Quad.easeOut });
  timeline.to(zoom, 1, { ...showElemWithAria }, "-=4");
  timeline.to(text, 1.5, {...revealFromBelow, ...easeOut}, "-=3")


};

const Component = scene(timeline(C7S12, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
