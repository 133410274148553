import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import PathReveal from 'components/PathReveal';
import {
  easeOutQuad, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-1.scss';

const C7S1 = class extends React.Component {
  render() {
    const {position, data} = this.props;
    const {background} = data;

    const start = 0.65
    const end = 1 - start
    const linePosition = start + (easeOutQuad(position) * end)

    return (
      <FullScreenContainer className="chapter-7-scene-1">
        <div ref="zoom">
          <FullScreenImage
            image={background}
            aspectRatio={8 / 5}
            alt="map of march from selma to montgomery"
            className="chapter-7-scene-1__background">
            <PathReveal
              viewBox="0 0 1440 900"
              position={linePosition}
              constant={78}
              strokeWidth={5}
              path="M299.09,277.56c0,0-2.56,12.31,0,14.61c2.56,2.29,6.22,3.67,10.35,5.96c4.13,2.29,12.56,5.5,19.58,8.71
              c7.02,3.21,13.07,11.01,17.61,15.14c4.54,4.13,16,13.76,17.84,16.51c1.83,2.75,1.37,6.42,8.25,10.09
              c6.88,3.67,17.43,4.59,25.22,5.96c7.8,1.38,11.92,0.92,15.59,2.75c3.67,1.83,11.92,6.42,14.21,6.88c2.29,0.46,9.63,1.72,15.82,3.1
              c6.19,1.38,17.2,3.79,19.95,4.47c2.75,0.69,8.94,2.41,12.04,3.44c3.1,1.03,9.29,1.03,13.76,1.72c4.47,0.69,15.82,2.41,19.6,4.13
              c3.78,1.72,11.69,7.91,13.07,9.29c1.38,1.38,3.44,5.16,7.22,7.57c3.78,2.41,16.85,7.57,17.88,7.91c1.03,0.34,18.23,5.85,22.01,6.54
              c3.78,0.69,16.16,6.88,19.6,8.26c3.44,1.38,11.69,5.16,15.48,5.85c3.78,0.69,16.51,4.13,21.32,4.47c4.82,0.34,17.54-0.69,21.32,0
              c3.78,0.69,7.91,1.03,13.07,2.75c5.16,1.72,7.22,5.16,12.72,5.5c5.5,0.34,21.67,1.51,25.79,1.38c20.98-0.69,22.35-3.44,28.32-4.36
              c5.96-0.92,16.97-2.29,22.47-2.29h12.84c6.42,0,11.46-2.29,15.59-3.21c4.13-0.92,11.92-1.84,18.8-1.38
              c6.88,0.46,15.13,0.46,24.3,0.46c9.17,0,30.72,0,36.68-0.46c5.96-0.46,12.38-2.75,20.18-6.88c7.8-4.13,6.88-6.42,14.67-11.47
              c7.8-5.05,12.84-5.5,16.97-7.34c4.13-1.84,3.21-4.13,8.25-4.59c5.04-0.46,3.21,2.29,8.71-0.92c5.5-3.21,11.92-10.09,15.13-10.55
              c3.21-0.46,2.29-13.76,2.29-19.26c0-5.5,2.29-7.05,4.13-12.7c1.83-5.65,1.38-10.69,7.8-12.53c6.42-1.83,24.76-10.09,29.35-11.92
              c4.58-1.84,4.58-1.38,10.55-0.92c5.96,0.46,10.09-1.38,11.46-4.13c1.38-2.75,4.59-5.05,4.59-5.05l12.84,6.88
              c0,0,3.67,10.09,9.17,12.84c5.5,2.75,11.01,7.34,16.51,7.8c5.5,0.46,8.25-3.21,8.25-3.21s4.13-14.68,4.13-16.97
              c0-2.29,0.92-11.93,4.13-14.68c3.21-2.75,4.13-6.42,7.8-9.63c3.67-3.21,8.11-3.21,8.64-5.96"
            />

            <div className="chapter-7-scene-1__background__labels">
              <p className="chapter-7-scene-1__background__labels__label selma">
                Selma
              </p>
              <p className="chapter-7-scene-1__background__labels__label montgomery">
                Montgomery
              </p>
            </div>
          </FullScreenImage>
        </div>

        <div className="chapter-7-scene-1__text">
          <p {...tabbable}>
            <span ref="text1" className="large">
              Two days after LBJ’s address on Voting Rights…
            </span>
            <span ref="text2">
              ...Judge Frank Johnson issues a ruling condemning the violence on
              Bloody Sunday.
              <br />
              <br />
              <span ref="text3" >
                He approves SCLC's plans for a new{' '}<span className="highlight">Selma to Montgomery</span> march.
              </span>
            </span>
          </p>
        </div>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-1/images/ch7-map-selma_to_montgomery.jpg" }) {
      ...largeImage
    }
  }
`;

const animation = ({context, timeline, easeOut}) => {
  const { zoom, text1, text2, text3 } = context.refs;


  TweenMax.set(zoom, {scale: 1.4, ...hideElementWithAria});
  TweenMax.set([text1, text2, text3], {...hideFromBelow})
  timeline.to(zoom, 4, { scale: 1, ease: Quad.easeOut });
  timeline.to(zoom, 1, { ...showElemWithAria }, "-=4");
  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut}, "-=3")
  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut}, "-=2.75")
  timeline.to(text3, 1.5, {...revealFromBelow, ...easeOut}, "-=2.5")
  timeline.markers.createTimelineMarker();

};

const Component = scene(timeline(C7S1, {animation}));

export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
