import React from 'react';
import FullScreenVideoPlayer from 'components/FullScreenVideoPlayer';
import scene from 'components/scene';
import video from './videos/ch7-SCRUB-03.mp4';

const C7S9 = class extends React.Component {
  render() {
    return (
      <FullScreenVideoPlayer src={video} alt='video clip of marchers' muted autoplay loop />
    );
  }
};

export default scene(C7S9, {duration: 3, stopAtEnd: false});
