import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {TweenMax} from 'gsap';
import {
  hideFromBelow, revealFromBelow
} from 'utils/animation';import {Link} from 'gatsby';
import timeline from 'components/timelineComponent';
import {tabbable} from 'utils/accessibility'

import 'styles/components/quote.scss';

const Quote = class extends React.Component {

  render() {
    const {quote, name, title} = this.props;
    return (
      <div {...tabbable} className="quote">
        <p ref="quote" className="quote__text">{`${quote}\"`}</p>
        <div className="quote__attribution">
          <p ref="name" className="quote__attribution__name">{name}</p>
          <p ref="title" className="quote__attribution__title">{title}</p>
        </div>
      </div>
    );
  }
};


const animation = ({context, timeline, easeOut}) => {
  const { quote, name, title } = context.refs;

  TweenMax.set([quote, name, title], {...hideFromBelow});
  timeline.to(quote, 1.5, { ...revealFromBelow, ...easeOut });
  timeline.to(name, 1.5, { ...revealFromBelow, ...easeOut }, "-=1.25");
  timeline.to(title, 1.5, { ...revealFromBelow, ...easeOut }, "-=1.25");
};


Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
};

export default timeline(Quote, {animation});
