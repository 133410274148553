import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, { Expo, Linear } from 'gsap/TweenMax';
import SplitView, { viewTypes } from 'templates/SplitView';
import timeline from 'components/timelineComponent';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene';
import Image from 'components/Image';
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-16.scss';

const C7S16 = class extends React.Component {
  render() {
    const {background, foreground} = this.props.data;
    // console.log(background, foreground)

    return (
      <SplitView
        className="chapter-7-scene-16"
        viewType={viewTypes.TWO_THIRDS}
        fullWidthPanel={
          <div>
            <Image className="chapter-7-scene-16__background" image={background} sizes="100vw" />
            <div ref="fg">
              <Image className="chapter-7-scene-16__fg" image={foreground} sizes="100vw" alt="U.S. President Lyndon B. Johnson hands a pen to civil rights leader Rev. Martin Luther King Jr. during the the signing of the voting rights act as officials look on behind them, Washington, D.C., August 6, 1965. (Photo by Washington Bureau/Getty Images)" showAria />
            </div>
          </div>
        }
        leftPanel={
          <div {...tabbable} className="chapter-7-scene-16__text">
            <p ref="text1">
              Five months after Bloody Sunday, on August 6, 1965—as a result of
              the protests in Selma—
            </p>
            <p ref="text2" className="large uppercase">
              the 1965
              <span className="highlight">Voting Rights Act</span> passes
            </p>
            <p ref="text3">
              with large, bipartisan majorities in both houses of Congress.
            </p>
          </div>
        }
      />
    );
  }
};


const animation = ({context, timeline, easeOut}) => {
  const { text1, text2, text3, fg } = context.refs;

  TweenMax.set([text1, text2, text3], { ...hideFromBelow });
  TweenMax.set(fg, { ...hideElementWithAria });

  timeline.to(text1, 1.5, { ...revealFromBelow, ...easeOut });
  timeline.to(text2, 1.5, { ...revealFromBelow, ...easeOut }, "-=1.25");
  timeline.to(text3, 1.5, { ...revealFromBelow, ...easeOut }, "-=1.25");
  timeline.to(fg, 2, { ...showElemWithAria, ease: Linear.easeNone}, "-=2");

};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-16/images/ch-7-image-1965-vra-passes-bg.png" }) {
      ...largeImage
    }
    foreground: file(relativePath: { eq: "chapter-7/scene-16/images/ch-7-image-1965-vra-passes-fg.png" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S16, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
