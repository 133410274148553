import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FullScreenImage from 'components/FullScreenImage';
import scene from 'components/scene';
import timeline from 'components/timelineComponent';

const C7S4b = class extends React.Component {
  render() {
    const {img} = this.props.data;
    return (
      <FullScreenImage
        image={img.childImageSharp.fluid.src}
        aspectRatio={8 / 5}
        className="chapter-7-scene-4b"
        alt="Selma to Montgomery March for voting rights: 1965"
        showAria
      />
    );
  }
};


const query = graphql`
  query {
    img: file(relativePath: { eq: "chapter-7/scene-4b/images/ch-7-image-march-cheering-flag@2x.jpg" }) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(C7S4b));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
