import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax from 'gsap/TweenMax';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import PathReveal from 'components/PathReveal';
import {getPercentageBetweenBounds} from 'utils';
import scene from 'components/scene';
import Image from 'components/Image';
import {TimelineMarkers} from 'utils/scene'
import {
  easeOutQuad, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-7.scss';

const C7S7 = class extends React.Component {
  render() {
    const {position} = this.props;
    const {
      map,
      marchers,
    } = this.props.data;

        const start = 0.4
        const end = 0.8 - start
        const linePosition = start + (easeOutQuad(position) * end)

    return (
      <FullScreenContainer>
        <div ref="zoom">
          <FullScreenImage
            image={map.childImageSharp.fluid.src}
            aspectRatio={8/5}
            className="chapter-7-scene-7"
          >
            <div ref="marchersImage">
              <Image className="chapter-7-scene-7__marchers" showAria image={marchers} sizes="100vw" alt="March From Selma Civil Rights leader Dr. Martin Luther King, Jr. and wife, Coretta Scott King (R, w. bonnet and sunglasses) looking energized as they lead demonstrators on the fourth day of their march from Selma to Montgomery AL. (Photo by Ben Martin/Getty Images)"/>
            </div>
            <div className="chapter-7-scene-7__path">
              {/* <img src={path} /> */}
            </div>
            <PathReveal
              position={linePosition}
              constant={71}
              viewBox="0 0 1440 900"
              path="M360.47,192.2c7.73,16.45,12.37,33.15,15.19,50.86,1.12,7.05,2.17,12.66,6.74,18.31,6.64,8.21,15.49,9.41,25.3,13.06,14.35,5.33,27.1,12.27,38,22.76s20.2,22.83,32.2,31.9c12.38,9.35,28.8,8.78,43.25,14.19,8,3,13.57,10.36,22.31,11.92,10.29,1.84,21.14,1.37,31.38,4,9.94,2.59,17.45,9.16,28,9.18,9,0,17.73-.62,26.75-.18,7.47.37,17-1,24.17,1,6.72,1.8,13.12,6.87,18.51,11.17,4.19,3.34,8.33,7.6,12.75,11.38,7.46,6.37,12.22,7.08,22.08,8.53,6.44.94,14.69,1.54,20.42,4.57,7.15,3.79,12.12,9.11,19.73,11.88a52,52,0,0,0,7,2.44,12.26,12.26,0,0,1,4.53,1.81c4.06,2.83,13.22,4.39,17.47,5.72,8.45,2.65,17,5.6,25.84,7.21a235,235,0,0,0,31.32,3.5c7.79.34,15.23-1,23.09,1.18a179.29,179.29,0,0,0,58,5.93c9-.5,17.81-.43,27.06-2.61a236.87,236.87,0,0,1,30.24-5.3c11.81-1.24,23.32-2.29,35.37-3.27s28.14-7.45,40.26-3.34"
            />
          </FullScreenImage>
        </div>
        <div {...tabbable} className="chapter-7-scene-7__text">
          <p ref="text1">There are threats of assassination attempts on King,</p>
          <p ref="text2"className="alt">but that doesn’t stop the marchers—even though they fear for their own safety as well.</p>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {zoom, text1, text2, marchersImage} = context.refs;


  TweenMax.set(zoom, {scale: 1.4, ...hideElementWithAria});
  TweenMax.set([text1, text2], {...hideFromBelow});
  TweenMax.set(marchersImage, {...hideElementWithAria});

  timeline.to(zoom, 4, { scale: 1, ease: Quad.easeOut });
  timeline.to(zoom, 1, { ...showElemWithAria }, "-=4");


  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut}, "-=3");
  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut}, "-=2.75");
  timeline.to(marchersImage, 2, {...showElemWithAria, ease: Linear.easeNone}, "-=2.5");

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    map: file(relativePath: { eq: "chapter-7/scene-7/images/ch-7-image-alabama-map-wide@2x.jpg" }) {
      ...largeImage
    }
    marchers: file(relativePath: { eq: "chapter-7/scene-7/images/ch-7-image-king-leading-marchers@2x.png" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C7S7, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
