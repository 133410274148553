import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Modal from 'components/ChapterModal';
import scene from 'components/scene';
import Image from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-19.scss';

const C7S19 = class extends React.Component {
  render() {
    const {
      background,
      clipboard,
      courthouse,
      people,
      thesouth,
      voting,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-7-scene-19">
        <Image className="chapter-7-scene-19__background" image={background} sizes="100vw" />
        <div className="chapter-7-scene-19__text">
          <h1 {...tabbable} className="uppercase">The Voting Rights Act of 1965</h1>
          <Modal
            className="chapter-7-scene-19__modal"
            buttonEl={
              <button className="chapter-7-scene-19__modal__button">
                Explore
              </button>
            }>
            <div className="chapter-7-scene-19__modal__content">
              <p>The Voting Rights act of 1965 includes several key points:</p>
              <Image className="chapter-7-scene-19__modal__content__image" image={people} />
              <p className="alt">No requirement or procedure may be used to
deny the vote to any citizen of the United States
based on race.</p>
              <Image className="chapter-7-scene-19__modal__content__image" image={clipboard} />
              <p className="alt">No “test” (e.g., literacy or civics tests) or “device” (e.g., grandfather clause) may be used as a prerequisite
for voting.</p>
              <Image className="chapter-7-scene-19__modal__content__image" image={thesouth} />
              <p className="alt">States and localities--mainly in the South-- that most severely restricted voting rights before this 1965 law will now require special attention.</p>
              <p className="alt">The Voting Rights Act identified these as states that used any “test or device” to limit voting based on race or color
on November 1, 1964, or places where less than 50 percent
of people of voting age were registered to vote on
November 1, 1964.</p>
              <Image className="chapter-7-scene-19__modal__content__image" image={courthouse} />
              <p className="alt">If one of the states or localities requiring special attention wants to change voter qualifications or voting procedures, it must seek federal court approval to do so.</p>
              <Image className="chapter-7-scene-19__modal__content__image" image={voting} />
              <p className="alt">Federal examiners and observers may, at the request of the U.S. attorney general, oversee
voter registration, voting and vote-counting sites.</p>
            </div>
          </Modal>
        </div>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-19/images/ch-7-image-voting-rights-act-paper@3x.jpg" }) {
      ...largeImage
    }
    clipboard: file(relativePath: { eq: "chapter-7/scene-19/images/ch-7-icon-clipboard@2x.png" }) {
      ...doodleImage
    }
    courthouse: file(relativePath: { eq: "chapter-7/scene-19/images/ch-7-icon-courthouse@2x.png" }) {
      ...doodleImage
    }
    people: file(relativePath: { eq: "chapter-7/scene-19/images/ch-7-icon-people@2x.png" }) {
      ...doodleImage
    }
    thesouth: file(relativePath: { eq: "chapter-7/scene-19/images/ch-7-icon-thesouth@2x.png" }) {
      ...doodleImage
    }
    voting: file(relativePath: { eq: "chapter-7/scene-19/images/ch-7-icon-voting@2x.png" }) {
      ...doodleImage
    }
  }
`;

const Component = scene(C7S19);
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
