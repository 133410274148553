import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import CSSPlugin from 'gsap/CSSPlugin'; // eslint-disable-line no-unused-vars
import TweenMax, {Expo, Linear} from 'gsap/TweenMax';
import {numberWithCommas} from 'utils';
import {placeholder} from 'utils/animation';
import SplitView, {viewTypes} from 'templates/SplitView';
import timeline from 'components/timelineComponent';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'
import Image from 'components/Image';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-18.scss';

const C7S18 = class extends React.Component {
  render() {
    const {
      background,
      photo1,
      photo2,
      photo3,
    } = this.props.data;
    return (
      <SplitView
        className="chapter-7-scene-18"
        viewType={viewTypes.ONE_THIRD}
        fullWidthPanel={
          <div ref="background">
            <Image className="chapter-7-scene-18__background" image={background} sizes="100vw"/>
          </div>
        }
        leftPanel={
          <div {...tabbable} className="chapter-7-scene-18__text">
            <h1>1968</h1>
            <p>African American voter registration in the South was <span className="highlight">62%</span> (up from 43% in 1964). </p>
            <p className="alt">In Alabama, it increased from <span className="highlight">23% to 57%</span>.</p>
            <p className="alt">In Mississippi it rose from <span className="highlight">7% to 54%</span>.</p>
          </div>
        }
        rightPanel={
          <div className="chapter-7-scene-18__photos">
            <div className="chapter-7-scene-18__photos__photo photo1">
              <div ref="photo1">
                <Image image={photo1} sizes="50vw" alt="Black Women Voting in Alabama Several African American women vote for the first time while other wait in line in an Alabama town. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)" showAria />
              </div>
            </div>
            <div className="chapter-7-scene-18__photos__photo photo2">
              <div ref="photo2">
                <Image image={photo2} sizes="50vw" alt="African American Woman Voting A black woman in Alabama votes for the first time after enactment of the Voting Rights Act. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)" showAria/>
              </div>
            </div>
            <div className="chapter-7-scene-18__photos__photo photo3">
              <div ref="photo3">
                <Image image={photo3} sizes="50vw" alt="Blacks Registering to Vote in Alabama Long lines of African Americans wait to register to vote in a makeshift office in Alabama after passage of the Voting Rights Act. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)" showAria />
              </div>
            </div>
          </div>
        }
      />
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {photo1, photo2, photo3} = context.refs;
  const {innerHeight} = window;


  TweenMax.set(photo1, {y: innerHeight, rotation: '10deg'});
  TweenMax.set(photo2, {y: innerHeight, rotation: '-10deg'});
  TweenMax.set(photo3, {y: innerHeight, rotation: '10deg'});

  placeholder(timeline, 0.5)

  timeline.to(photo1, 1.5, {y: 0, rotation: '0deg', ...easeOut});
  timeline.to(photo2, 1.5, {y: 0, rotation: '0deg', ...easeOut}, '-=1.2');
  timeline.to(photo3, 1.5, {y: 0, rotation: '0deg', ...easeOut}, '-=1.2');

};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-18/images/ch-7-image-voting-registration-line-dark@2x.jpg" }) {
      ...largeImage
    }
    photo1: file(relativePath: { eq: "chapter-7/scene-18/images/ch7-image-black_voter_registration-a.jpg" }) {
      ...mediumImage
    }
    photo2: file(relativePath: { eq: "chapter-7/scene-18/images/ch7-image-black_voter_registration-b.jpg" }) {
      ...mediumImage
    }
    photo3: file(relativePath: { eq: "chapter-7/scene-18/images/ch7-image-black_voter_registration-c.jpg" }) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(C7S18, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
