import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo} from 'gsap/TweenMax';
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import SplitView from 'templates/SplitView';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import {TimelineMarkers} from 'utils/scene';
import scene from 'components/scene';
import Image from 'components/Image';
import {tabbable} from 'utils/accessibility'

import './chapter-7-scene-3.scss';

const C7S3 = class extends React.Component {
  render() {
    const {
      background,
      circle,
      chapel,
    } = this.props.data;
    return (
      <FullScreenContainer className="chapter-7-scene-3">
        <SplitView
          leftPanel={
            <div ref="text" className="chapter-7-scene-3__text">
              <h2 {...tabbable} ref="text1" className="uppercase">March 21, 1965</h2>
              <p {...tabbable} ref="text2">Thousands of marchers leave Brown Chapel in Selma, headed for Montgomery, sleeping in fields along the way.</p>
            </div>
          }
          fullWidthPanel={
            <FullScreenImage
              image={background.childImageSharp.fluid.src}
              aspectRatio={8/5}
              className="chapter-7-scene-3__background">
              <div ref="chapel" className="chapter-7-scene-3__chapel">
                <div ref="chapelImage">
                  <Image className="chapter-7-scene-3__chapel__image" alt="Crowd gathers outside the Brown Chapel AME church in Selma, Ala., to start their long trek to the state capital, 54 miles away, in a demonstration for voting rights, March 1965. (AP Photo)" showAria image={chapel} />
                </div>
                <div className="chapter-7-scene-3__chapel__overlay">
                  <svg viewBox="0 0 100 62.5" preserveAspectRatio="none">
                    <defs>
                      <radialGradient id="chapelMaskGradient">
                        <stop offset="10%" stopColor="black" />
                        <stop ref="stop" offset="95%" stopColor="white" />
                      </radialGradient>

                      <mask id="chapelMask">
                        <rect width="100%" height="100%" fill="white" />
                        <circle ref="circleMask" cx="56%" cy="52.5%" r="15%" fill="url('#chapelMaskGradient')" />
                      </mask>
                    </defs>
                    <rect width="100%" height="100%" fill="black" mask="url(#chapelMask)" />
                  </svg>
                </div>
              </div>
              <div ref="label" className="chapter-7-scene-3__background__label">
                <Image image={circle} />
                <p>Brown Chapel</p>
              </div>
            </FullScreenImage>
          } />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {text, text1, text2, label, chapel, chapelImage, stop, circleMask} = context.refs;

  TweenMax.set([text1, text2], {...hideFromBelow})
  TweenMax.set([label, text], {...showElemWithAria});
  TweenMax.set(chapel, {z: 300});
  TweenMax.set(chapelImage, {x: '0.5%', y: '-5%'});
  TweenMax.set(stop, {attr: {'stop-color': 'white'}});
  TweenMax.set(circleMask, {attr: {r: '15%'}});

  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut})
  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25")

  timeline.markers.createTimelineMarker()

  timeline.to(text, 0.5, {...hideElementWithAria});
  timeline.to(label, 0.5, {...hideElementWithAria}, '-=0.5');
  timeline.to(chapel, 3, {z: 0, ease: Expo.easeInOut}, '-=0.5');
  timeline.to(chapelImage, 3, {x: '0%', y: '0%', ease: Expo.easeInOut}, '-=3');
  timeline.to(circleMask, 1.5, {attr: {r: '100%'}, ease: Expo.easeIn}, '-=3');
  timeline.to(stop, 0.5, {attr: {'stop-color': 'black'}}, '-=1.5');

  placeholder(timeline, 0.5);


};

const query = graphql`
  query {
    background: file(relativePath: { eq: "chapter-7/scene-3/images/ch-7-image-selma-map-closeup@3x.jpg" }) {
      ...mediumImage
    }
    circle: file(relativePath: { eq: "chapter-7/scene-3/images/ch-7-doodle-circle@2x.png" }) {
      ...doodleImage
    }
    chapel: file(relativePath: { eq: "chapter-7/scene-3/images/ch-7-image-browns-chapel@2x.jpg" }) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(C7S3, {animation}), {
  stopAtEnd: false,
});

export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
