import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import timeline from 'components/timelineComponent';

import movieClip from './ch7-SCENE-12-March.mp4';
import illustration from './images/ch-7-illustration-mlk-stage@2x.png';

import './chapter-7-scene-14.scss';

import CapitolGroundsModal from './CGSModal';


const C7S13 = class extends React.Component {
  render() {
    const {
      endCard,
      startCard,
    } = this.props.data;

    return (
      <div className="chapter-7-scene-14">
        <MovieScene
          sceneNumber={4}
          sceneSubtitle={
            <span>
              <span className="italic">SELMA</span> SCENE 12
            </span>
          }
          sceneDescription="The March to Montgomery"
          firstImage={startCard.childImageSharp.fluid.src}
          lastImage={endCard.childImageSharp.fluid.src}
          videoSrc={movieClip}
          modalComponent={(() => <CapitolGroundsModal />)}
        />
      </div>
    );
  }
};

const query = graphql`
  query {
    endCard: file(relativePath: { eq: "chapter-7/scene-14/images/ch-7-image-march-to-montgomery-replay@2x.jpg" }) {
      ...mediumImage
    }
    startCard: file(relativePath: { eq: "chapter-7/scene-14/images/ch-7-image-march-to-montgomery-still@2x.jpg" }) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(C7S13));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
