import React from 'react';
import scene from 'components/scene';

import FullScreenVideoPlayer from 'components/FullScreenVideoPlayer';
import video from './videos/ch7-SCRUB-02.mp4';

const C7S6 = class extends React.Component {
  render() {
    return (
      <FullScreenVideoPlayer src={video} muted autoplay loop />
    );
  }
};

export default scene(C7S6, {duration: 3, stopAtEnd: false});
