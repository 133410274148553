export const Chapter7Qa = [
  {
    questionNumber: '7.01',
    question: 'What provisions in the Voting Rights Act ensured that the Act would remain intact over time? Have these provisions held?',
    answer: `The VRA ensured that in any place with a history of discrimination any proposed changes to the way voting happened would require court approval. In addition, federal observers would be present at polling places to ensure voting took place fairly. The 2013 Supreme Court  ruling in Shelby v. Holder stripped the VRA of these provisions.`,
  },
  {
    questionNumber: '7.02',
    question: 'What barriers faced by Dallas County residents did the Voting Rights Act of 1965 ban?',
    answer: `The law banned literacy tests, poll taxes, and the requirement that someone  vouch for you when registering. Federal examiners oversaw the registration process to ensure it was fair.`,
  },
  {
    questionNumber: '7.03',
    question: 'How did the Voting Rights Act of 1965 impact voting in the South?',
    answer: `In most states there was a large increase in registered black voters between 1960 and 1971 and a more moderate increase from 1971 to 2008.`,
  },
  {
    questionNumber: '7.04',
    question: 'How does the VRA compare to the 15th Amendment? What’s similar, and what’s different?',
    answer: `Like the 15th amendment, it guarantees the right to vote regardless of race.  It also gave the federal government strong enforcement powers to overcome discriminatory voter suppression tactics by states and localities.`,
  },
];
